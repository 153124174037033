import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { DropDownIcon, InputText } from "../styles";
import { countryFlags } from "../static";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../firebase/firebase";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import emailjs from "@emailjs/browser";
import { useMediaQuery } from "@mui/material";

const Register = () => {
  const [error, setError] = useState(true);
  const [country, setCountry] = useState("Select your country");
  const Navigate = useNavigate();
  const mobile = useMediaQuery("(max-width: 380px)");

  async function sendAdminEmail(firstname) {
    const params = {
      details: `A new user ${firstname} just signed up on your platform.`,
      action_name: "Sign up",
      to_email: "support@primeventurepartners.net",
      from_name: "Notifications",
      logo_link: "https://primeventurepartners.net/logo.png",
      to_name: "Admin",
      broker_to_link: "https://app.primeventurepartners.net",
      broker_name: "Prime Venture Partners",
    };

    emailjs
      .send("service_x4dbltd", "template_kkyhhxq", params, "9IOr2_lHheTH7RW1k")
      .then(() => {})
      .catch((error) => {});
  }

  async function sendWelcomeEmail(email, firstname) {
    const params = {
      from_name: "Prime Venture Partners",
      to_name: firstname,
      logo_link: "https://primeventurepartners.net/logo.png",
      to_login: "https://app.primeventurepartners.net",
      owner: "Global Capital Securities and Commodities Limited",
      broker_support_email: "mailto:support@primeventurepartners.net",
      to_email: email,
      from_email: "helpdesk@primeventurepartners.net",
      reply_to: "support@primeventurepartners.net",
    };

    emailjs
      .send("service_5jaijh8", "template_goqet9m", params, "9IOr2_lHheTH7RW1k")
      .then(() => {})
      .catch((error) => {});
  }

  const [isRevealConfirm, setIsRevealConfirm] = useState(false);
  const [isReveal, setIsReveal] = useState(false);

  function handleReveal(e) {
    e.preventDefault();

    setIsReveal(!isReveal);
  }

  function handleRevealConfirm(e) {
    e.preventDefault();
    setIsRevealConfirm(!isRevealConfirm);
  }

  return (
    <FormWrapper>
      <h1 className="title">Sign up to get started with trading.</h1>
      <Formik
        initialValues={{
          email: "",
          password: "",
          lastname: "",
          confirm: "",
          firstname: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = "Please enter your email address";
            setError(true);
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Please enter a valid email address";
            setError(true);
          }
          if (!values.lastname) {
            errors.lastname = "Please enter your name";
            setError(true);
          }
          if (!values.firstname) {
            errors.firstname = "Please enter your name";
            setError(true);
          }
          if (country === "Country") {
            errors.country = "Please enter your country";
            setError(true);
          }
          if (!values.password) {
            errors.password = "Please enter your password";
            setError(true);
          }
          if (values.password && values.password.length < 6) {
            errors.password =
              "Your password should be longer than six characters";
            setError(true);
          }
          if (values.confirm && values.confirm !== values.password) {
            errors.password = "Passwords do not match";
            setError(true);
          } else {
            setError(false);
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          const { email, firstname, password, lastname } = values;
          try {
            const user = await createUserWithEmailAndPassword(
              auth,
              email,
              password
            );
            if (user) {
              // Add a new document in collections "users and accounts"
              await setDoc(doc(db, "users", user.user.uid), {
                id: user.user.uid,
                firstname,
                email,
                lastname,
                country,
                photoURL: user.user.photoURL,
                admin: false,
                tradingProgress: 0,
                verified: false,
                blocked: false,
                accountType: "Starter",
                lastLogin: serverTimestamp(),
                registerDate: serverTimestamp(),
                withdrawalMaxLimit: 0,
              });

              await setDoc(doc(db, "watching", user.user.uid), {
                EURUSD: false,
              });

              await setDoc(doc(db, "profits", user.user.uid), {
                profits: 0,
              });

              await setDoc(doc(db, "additionalProfits", user.user.uid), {
                BTC: 0,
                ETH: 0,
                BCH: 0,
              });

              await setDoc(doc(db, "balance", user.user.uid), {
                signalBalance: 0,
              });

              await setDoc(doc(db, "accounts", user.user.uid), {
                practice: {
                  Fiat: {
                    id: 1,
                    asset: "USD",
                    name: "United Stated Dollars",
                    type: "Fiat",
                    value: 0,
                  },
                  Crypto: {
                    BTC: {
                      id: 1,
                      asset: "BTC",
                      alt: "BTC",
                      name: "Bitcoin",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    ETH: {
                      id: 2,
                      asset: "ETH",
                      alt: "ETH",
                      name: "Ethereum",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    SOL: {
                      id: 3,
                      asset: "SOL",
                      alt: "SOL",
                      name: "Solana",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    BCH: {
                      id: 4,
                      asset: "BCH",
                      alt: "BCH",
                      name: "Bitcoin Cash",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    LTC: {
                      id: 5,
                      asset: "LTC",
                      alt: "LTC",
                      name: "Litecoin",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    DOGE: {
                      id: 6,
                      asset: "DOGE",
                      alt: "DOGE",
                      name: "Dogecoin",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    USDT: {
                      id: 7,
                      asset: "USDT",
                      alt: "USDT",
                      name: "Tether",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    MATIC: {
                      id: 8,
                      asset: "MATIC",
                      alt: "MATIC",
                      name: "Polygon",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    AVAX: {
                      id: 9,
                      asset: "AVAX",
                      alt: "AVAX",
                      name: "Avalanche",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    USDC: {
                      id: 10,
                      asset: "USDC",
                      alt: "USDC",
                      name: "USD Coin",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    AAVE: {
                      id: 11,
                      asset: "AAVE",
                      alt: "AAVE",
                      name: "AAVE",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    ALGO: {
                      id: 12,
                      asset: "ALGO",
                      alt: "ALGO",
                      name: "Algorand",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    ANC: {
                      id: 13,
                      asset: "ANC",
                      alt: "ANCUSD",
                      name: "Anchor Protocol",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    APE: {
                      id: 14,
                      asset: "APE",
                      alt: "APE",
                      name: "ApeCoin",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    AURORA: {
                      id: 15,
                      asset: "AURORA",
                      alt: "AURORAUSD",
                      name: "Aurora",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    AXS: {
                      id: 16,
                      asset: "AXS",
                      alt: "AXS",
                      name: "Axie Infinity",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    BTG: {
                      id: 17,
                      asset: "BTG",
                      alt: "BTGUSD",
                      name: "Bitcoin Gold",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    BORING: {
                      id: 18,
                      asset: "BORING",
                      alt: "BORINGUSDT",
                      name: "Boring DAO",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    ADA: {
                      id: 19,
                      asset: "ADA",
                      alt: "ADA",
                      name: "Cardano",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    XCN: {
                      id: 20,
                      asset: "XCN",
                      alt: "XCNUSD",
                      name: "Chain",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    LINK: {
                      id: 21,
                      asset: "LINK",
                      alt: "LINK",
                      name: "ChainLink",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    CMDX: {
                      id: 22,
                      asset: "CMDX",
                      alt: "CMDX",
                      name: "Comdex",
                      type: "Crypto",
                      value: 0,
                      tradable: false,
                    },
                    CRO: {
                      id: 23,
                      asset: "CRO",
                      alt: "CROUSD",
                      name: "Cronos",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    DAI: {
                      id: 24,
                      asset: "DAI",
                      alt: "DAI",
                      name: "Dai",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    DASH: {
                      id: 25,
                      asset: "DASH",
                      alt: "DASHUSD",
                      name: "Dash",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    MANA: {
                      id: 26,
                      asset: "MANA",
                      alt: "MANA",
                      name: "Decentraland",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    DFL: {
                      id: 27,
                      asset: "DFL",
                      alt: "DFLUSD",
                      name: "Defi Land",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    NRG: {
                      id: 28,
                      asset: "NRG",
                      alt: "NRGUSDT",
                      name: "Energi",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    ETC: {
                      id: 29,
                      asset: "ETC",
                      alt: "ETC",
                      name: "Ethereum Classic",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    EVMOS: {
                      id: 30,
                      asset: "EVMOS",
                      alt: "EVMOSUSDT",
                      name: "Evmos",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    GT: {
                      id: 31,
                      asset: "GT",
                      alt: "GTUSD",
                      name: "Gate Token",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    GO: {
                      id: 32,
                      asset: "GO",
                      alt: "GOUSD",
                      name: "GoChain",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    LN: {
                      id: 33,
                      asset: "LN",
                      alt: "LNUSDT",
                      name: "Link",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    XMR: {
                      id: 34,
                      asset: "XMR",
                      alt: "XMR",
                      name: "Monero",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    NEXO: {
                      id: 35,
                      asset: "NEXO",
                      alt: "NEXOUSD",
                      name: "Nexo",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    OKB: {
                      id: 36,
                      asset: "OKB",
                      alt: "OKBUSDT",
                      name: "OKB",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    OP: {
                      id: 37,
                      asset: "OP",
                      alt: "OPUSDT",
                      name: "Optimism",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    OGN: {
                      id: 38,
                      asset: "OGN",
                      alt: "OGNUSDT",
                      name: "Origin Protocol",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    ORN: {
                      id: 39,
                      asset: "ORN",
                      alt: "ORNUSDT",
                      name: "Orion Protocol",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    DOT: {
                      id: 40,
                      asset: "DOT",
                      alt: "DOT",
                      name: "Polkadot",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    XPR: {
                      id: 41,
                      asset: "XPR",
                      alt: "XPRUSDT",
                      name: "Proton",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    RAINI: {
                      id: 42,
                      asset: "RAINI",
                      alt: "RAINIUSD_165C19",
                      name: "Rainicorn",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    RARI: {
                      id: 43,
                      asset: "RARI",
                      alt: "RARIUSD",
                      name: "Rarible",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    SFP: {
                      id: 44,
                      asset: "SFP",
                      alt: "SFPUSDT",
                      name: "Safepal",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    SHIB: {
                      id: 45,
                      asset: "SHIB",
                      alt: "SHIB",
                      name: "Shiba Inu",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    XLM: {
                      id: 46,
                      asset: "XLM",
                      alt: "XLM",
                      name: "Stellar",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    STEP: {
                      id: 47,
                      asset: "STEP",
                      alt: "STEPUSD",
                      name: "Step Finance",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    GMT: {
                      id: 48,
                      asset: "GMT",
                      alt: "GMTUSDT",
                      name: "Stepn",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    SUSHI: {
                      id: 49,
                      asset: "SUSHI",
                      alt: "SUSHI",
                      name: "Sushi",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    TLOS: {
                      id: 50,
                      asset: "TLOS",
                      alt: "TLOSUSD",
                      name: "Telos",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    XTZ: {
                      id: 51,
                      asset: "XTZ",
                      alt: "XTZ",
                      name: "Tezos",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    GRT: {
                      id: 52,
                      asset: "GRT",
                      alt: "GRTUSD",
                      name: "The Graph",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    TRX: {
                      id: 53,
                      asset: "TRX",
                      alt: "TRX",
                      name: "Tron",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    UNI: {
                      id: 54,
                      asset: "UNI",
                      alt: "UNIUSD",
                      name: "Uniswap",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    VET: {
                      id: 55,
                      asset: "VET",
                      alt: "VETUSD",
                      name: "Vechain",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    WING: {
                      id: 56,
                      asset: "WING",
                      alt: "WINGUSD",
                      name: "Wing Finance",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    WXT: {
                      id: 57,
                      asset: "WXT",
                      alt: "WXTUSDT",
                      name: "Wirex",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    ZEC: {
                      id: 58,
                      asset: "ZEC",
                      alt: "ZEXUSDT",
                      name: "Zcash",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    ZIG: {
                      id: 59,
                      asset: "ZIG",
                      alt: "ZIGUSDT",
                      name: "Zignaly",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                  },
                  Stock: {
                    AAPL: {
                      id: 1,
                      asset: "AAPL",
                      name: "Apple",
                      type: "Stock",
                      value: 0,
                    },
                    ABT: {
                      id: 2,
                      asset: "ABT",
                      name: "Abbot Labs",
                      type: "Stock",
                      value: 0,
                    },
                    ADBE: {
                      id: 3,
                      asset: "ADBE",
                      name: "Adobe",
                      type: "Stock",
                      value: 0,
                    },
                    ADI: {
                      id: 4,
                      asset: "ADI",
                      name: "Analog Devices",
                      type: "Stock",
                      value: 0,
                    },
                    AEMD: {
                      id: 5,
                      asset: "AEMD",
                      name: "Aethlon Medical",
                      type: "Stock",
                      value: 0,
                    },
                    AIG: {
                      id: 6,
                      asset: "AIG",
                      name: "American International Group",
                      type: "Stock",
                      value: 0,
                    },
                    AMC: {
                      id: 7,
                      asset: "AMC",
                      name: "AMC Holdings",
                      type: "Stock",
                      value: 0,
                    },
                    AMD: {
                      id: 8,
                      asset: "AMD",
                      name: "AMD",
                      type: "Stock",
                      value: 0,
                    },
                    AMT: {
                      id: 9,
                      asset: "AMT",
                      name: "American Tower",
                      type: "Stock",
                      value: 0,
                    },
                    AMZN: {
                      id: 10,
                      asset: "AMZN",
                      name: "Amazon",
                      type: "Stock",
                      value: 0,
                    },
                    APT: {
                      id: 11,
                      asset: "APT",
                      name: "Alpha Pro Tech",
                      type: "Stock",
                      value: 0,
                    },
                    ASML: {
                      id: 12,
                      asset: "ASML",
                      name: "ASML",
                      type: "Stock",
                      value: 0,
                    },
                    ATER: {
                      id: 13,
                      asset: "ATER",
                      name: "Aterian Inc",
                      type: "Stock",
                      value: 0,
                    },
                    AXP: {
                      id: 14,
                      asset: "AXP",
                      name: "American Express",
                      type: "Stock",
                      value: 0,
                    },
                    BA: {
                      id: 15,
                      asset: "BA",
                      name: "Boeing",
                      type: "Stock",
                      value: 0,
                    },
                    BABA: {
                      id: 16,
                      asset: "BABA",
                      name: "Alibaba",
                      type: "Stock",
                      value: 0,
                    },
                    BAC: {
                      id: 17,
                      asset: "BAC",
                      name: "Bank of America",
                      type: "Stock",
                      value: 0,
                    },
                    BIDU: {
                      id: 18,
                      asset: "BIDU",
                      name: "Baidu Inc",
                      type: "Stock",
                      value: 0,
                    },
                    BMY: {
                      id: 19,
                      asset: "BMY",
                      name: "Bristol Myers",
                      type: "Stock",
                      value: 0,
                    },
                    C: {
                      id: 20,
                      asset: "C",
                      name: "Citigroup",
                      type: "Stock",
                      value: 0,
                    },
                    CAT: {
                      id: 21,
                      asset: "CAT",
                      name: "Caterpillar",
                      type: "Stock",
                      value: 0,
                    },
                    CCO: {
                      id: 22,
                      asset: "CCO",
                      name: "Clear Channel Outdoor",
                      type: "Stock",
                      value: 0,
                    },
                    CEI: {
                      id: 23,
                      asset: "CEI",
                      name: "Camber Energy",
                      type: "Stock",
                      value: 0,
                    },
                    CHWY: {
                      id: 24,
                      asset: "CHWY",
                      name: "Chewy Inc",
                      type: "Stock",
                      value: 0,
                    },
                    CL: {
                      id: 25,
                      asset: "CL",
                      name: "Colgate-Palmolive",
                      type: "Stock",
                      value: 0,
                    },
                    CMCSA: {
                      id: 26,
                      asset: "CMCSA",
                      name: "Comcast",
                      type: "Stock",
                      value: 0,
                    },
                    COST: {
                      id: 27,
                      asset: "COST",
                      name: "Costco",
                      type: "Stock",
                      value: 0,
                    },
                    CRDF: {
                      id: 28,
                      asset: "CRDF",
                      name: "Cardiff Oncology Inc",
                      type: "Stock",
                      value: 0,
                    },
                    CRM: {
                      id: 29,
                      asset: "CRM",
                      name: "Salesforce Inc",
                      type: "Stock",
                      value: 0,
                    },
                    CSCO: {
                      id: 30,
                      asset: "CSCO",
                      name: "Cisco",
                      type: "Stock",
                      value: 0,
                    },
                    CVX: {
                      id: 31,
                      asset: "CVX",
                      name: "Chevron",
                      type: "Stock",
                      value: 0,
                    },
                    DIS: {
                      id: 32,
                      asset: "DIS",
                      name: "Disney",
                      type: "Stock",
                      value: 0,
                    },
                    EBAY: {
                      id: 33,
                      asset: "EBAY",
                      name: "Ebay",
                      type: "Stock",
                      value: 0,
                    },
                    FB: {
                      id: 34,
                      asset: "FB",
                      name: "Meta Platforms Inc",
                      type: "Stock",
                      value: 0,
                    },
                    FSLY: {
                      id: 35,
                      asset: "FSLY",
                      name: "Fastly Inc",
                      type: "Stock",
                      value: 0,
                    },
                    GE: {
                      id: 36,
                      asset: "GE",
                      name: "General Electric",
                      type: "Stock",
                      value: 0,
                    },
                    GEVO: {
                      id: 37,
                      asset: "GEVO",
                      name: "Gevo Inc",
                      type: "Stock",
                      value: 0,
                    },
                    GM: {
                      id: 38,
                      asset: "GM",
                      name: "General Motors",
                      type: "Stock",
                      value: 0,
                    },
                    GOOGL: {
                      id: 39,
                      asset: "GOOGL",
                      name: "Google",
                      type: "Stock",
                      value: 0,
                    },
                    GS: {
                      id: 40,
                      asset: "GS",
                      name: "Goldman Sachs",
                      type: "Stock",
                      value: 0,
                    },
                    HD: {
                      id: 41,
                      asset: "HD",
                      name: "Home Depot",
                      type: "Stock",
                      value: 0,
                    },
                    HON: {
                      id: 42,
                      asset: "HON",
                      name: "Honeywell",
                      type: "Stock",
                      value: 0,
                    },
                    IBM: {
                      id: 43,
                      asset: "IBM",
                      name: "IBM",
                      type: "Stock",
                      value: 0,
                    },
                    INMD: {
                      id: 44,
                      asset: "INMD",
                      name: "Inmode",
                      type: "Stock",
                      value: 0,
                    },
                    INTC: {
                      id: 45,
                      asset: "INTC",
                      name: "Intel",
                      type: "Stock",
                      value: 0,
                    },
                    JNJ: {
                      id: 46,
                      asset: "JNJ",
                      name: "Johnson & Johnson",
                      type: "Stock",
                      value: 0,
                    },
                    JPM: {
                      id: 47,
                      asset: "JPM",
                      name: "JP Morgan",
                      type: "Stock",
                      value: 0,
                    },
                    KO: {
                      id: 48,
                      asset: "KO",
                      name: "Coca Cola",
                      type: "Stock",
                      value: 0,
                    },
                    LEN: {
                      id: 49,
                      asset: "LEN",
                      name: "Lennar Corporation",
                      type: "Stock",
                      value: 0,
                    },
                    LVS: {
                      id: 50,
                      asset: "LVS",
                      name: "Las Vegas Sands",
                      type: "Stock",
                      value: 0,
                    },
                    MA: {
                      id: 51,
                      asset: "MA",
                      name: "MasterCard",
                      type: "Stock",
                      value: 0,
                    },
                    MDLZ: {
                      id: 52,
                      asset: "MDLZ",
                      name: "Mondelez",
                      type: "Stock",
                      value: 0,
                    },
                    MMM: {
                      id: 53,
                      asset: "MMM",
                      name: "3M Company",
                      type: "Stock",
                      value: 0,
                    },
                    MNST: {
                      id: 54,
                      asset: "MNST",
                      name: "Monster",
                      type: "Stock",
                      value: 0,
                    },
                    MO: {
                      id: 55,
                      asset: "MO",
                      name: "Attria Group",
                      type: "Stock",
                      value: 0,
                    },
                    MRIN: {
                      id: 56,
                      asset: "MRIN",
                      name: "Marin Software",
                      type: "Stock",
                      value: 0,
                    },
                    MRK: {
                      id: 57,
                      asset: "MRK",
                      name: "Merck",
                      type: "Stock",
                      value: 0,
                    },
                    MS: {
                      id: 58,
                      asset: "MS",
                      name: "Morgan Stanley",
                      type: "Stock",
                      value: 0,
                    },
                    MSFT: {
                      id: 59,
                      asset: "MSFT",
                      name: "Microsoft",
                      type: "Stock",
                      value: 0,
                    },
                    MSI: {
                      id: 60,
                      asset: "MSI",
                      name: "Motorola",
                      type: "Stock",
                      value: 0,
                    },
                    NFLX: {
                      id: 61,
                      asset: "NFLX",
                      name: "Netflix",
                      type: "Stock",
                      value: 0,
                    },
                    NKE: {
                      id: 62,
                      asset: "NKE",
                      name: "Nike",
                      type: "Stock",
                      value: 0,
                    },
                    NVDA: {
                      id: 63,
                      asset: "NVDA",
                      name: "Nvidia",
                      type: "Stock",
                      value: 0,
                    },
                    NVS: {
                      id: 64,
                      asset: "NVS",
                      name: "Novartis",
                      type: "Stock",
                      value: 0,
                    },
                    ORCL: {
                      id: 65,
                      asset: "ORCL",
                      name: "Oracle",
                      type: "Stock",
                      value: 0,
                    },
                    PEP: {
                      id: 66,
                      asset: "PEP",
                      name: "Pepsico",
                      type: "Stock",
                      value: 0,
                    },
                    PFE: {
                      id: 67,
                      asset: "PFE",
                      name: "Pfizer",
                      type: "Stock",
                      value: 0,
                    },
                    PG: {
                      id: 68,
                      asset: "PG",
                      name: "Procter & Gamble",
                      type: "Stock",
                      value: 0,
                    },
                    PYPL: {
                      id: 69,
                      asset: "PYPL",
                      name: "Paypal",
                      type: "Stock",
                      value: 0,
                    },
                    RACE: {
                      id: 70,
                      asset: "RACE",
                      name: "Ferrari",
                      type: "Stock",
                      value: 0,
                    },
                    RKLB: {
                      id: 71,
                      asset: "RKLB",
                      name: "Rocket Lab",
                      type: "Stock",
                      value: 0,
                    },
                    RL: {
                      id: 72,
                      asset: "RL",
                      name: "Ralph Lauren",
                      type: "Stock",
                      value: 0,
                    },
                    RWLK: {
                      id: 73,
                      asset: "RWLK",
                      name: "ReWalk Robotics",
                      type: "Stock",
                      value: 0,
                    },
                    SBUX: {
                      id: 74,
                      asset: "SBUX",
                      name: "Starbucks",
                      type: "Stock",
                      value: 0,
                    },
                    SNAP: {
                      id: 75,
                      asset: "SNAP",
                      name: "Snap Inc",
                      type: "Stock",
                      value: 0,
                    },
                    SSRM: {
                      id: 76,
                      asset: "SSRM",
                      name: "SSR Mining",
                      type: "Stock",
                      value: 0,
                    },
                    SQ: {
                      id: 77,
                      asset: "SQ",
                      name: "Square",
                      type: "Stock",
                      value: 0,
                    },
                    T: {
                      id: 78,
                      asset: "T",
                      name: "At&t",
                      type: "Stock",
                      value: 0,
                    },
                    TEVA: {
                      id: 79,
                      asset: "TEVA",
                      name: "Teva",
                      type: "Stock",
                      value: 0,
                    },
                    TM: {
                      id: 80,
                      asset: "TM",
                      name: "Toyota Motor",
                      type: "Stock",
                      value: 0,
                    },
                    TMUS: {
                      id: 81,
                      asset: "TMUS",
                      name: "T-Mobile",
                      type: "Stock",
                      value: 0,
                    },
                    TRIP: {
                      id: 82,
                      asset: "TRIP",
                      name: "TripAdvisor",
                      type: "Stock",
                      value: 0,
                    },
                    TSLA: {
                      id: 83,
                      asset: "TSLA",
                      name: "Tesla",
                      type: "Stock",
                      value: 0,
                    },
                    TSM: {
                      id: 84,
                      asset: "TSM",
                      name: "TSMC",
                      type: "Stock",
                      value: 0,
                    },
                    TWTR: {
                      id: 85,
                      asset: "TWTR",
                      name: "Twitter",
                      type: "Stock",
                      value: 0,
                    },
                    UNH: {
                      id: 86,
                      asset: "UNH",
                      name: "United Health Group",
                      type: "Stock",
                      value: 0,
                    },
                    V: {
                      id: 87,
                      asset: "V",
                      name: "Visa",
                      type: "Stock",
                      value: 0,
                    },
                    VZ: {
                      id: 88,
                      asset: "VZ",
                      name: "Verizon",
                      type: "Stock",
                      value: 0,
                    },
                    WFC: {
                      id: 89,
                      asset: "WFC",
                      name: "Wells Fargo",
                      type: "Stock",
                      value: 0,
                    },
                    WMT: {
                      id: 90,
                      asset: "WMT",
                      name: "Walmart",
                      type: "Stock",
                      value: 0,
                    },
                    XOM: {
                      id: 91,
                      asset: "XOM",
                      name: "Exxon Mobil",
                      type: "Stock",
                      value: 0,
                    },
                  },
                },

                live: {
                  Fiat: {
                    id: 1,
                    asset: "USD",
                    name: "United Stated Dollars",
                    type: "Fiat",
                    value: 0,
                  },
                  Crypto: {
                    BTC: {
                      id: 1,
                      asset: "BTC",
                      alt: "BTC",
                      name: "Bitcoin",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    ETH: {
                      id: 2,
                      asset: "ETH",
                      alt: "ETH",
                      name: "Ethereum",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    SOL: {
                      id: 3,
                      asset: "SOL",
                      alt: "SOL",
                      name: "Solana",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    BCH: {
                      id: 4,
                      asset: "BCH",
                      alt: "BCH",
                      name: "Bitcoin Cash",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    LTC: {
                      id: 5,
                      asset: "LTC",
                      alt: "LTC",
                      name: "Litecoin",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    DOGE: {
                      id: 6,
                      asset: "DOGE",
                      alt: "DOGE",
                      name: "Dogecoin",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    USDT: {
                      id: 7,
                      asset: "USDT",
                      alt: "USDT",
                      name: "Tether",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    MATIC: {
                      id: 8,
                      asset: "MATIC",
                      alt: "MATIC",
                      name: "Polygon",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    AVAX: {
                      id: 9,
                      asset: "AVAX",
                      alt: "AVAX",
                      name: "Avalanche",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    USDC: {
                      id: 10,
                      asset: "USDC",
                      alt: "USDC",
                      name: "USD Coin",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    AAVE: {
                      id: 11,
                      asset: "AAVE",
                      alt: "AAVE",
                      name: "AAVE",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    ALGO: {
                      id: 12,
                      asset: "ALGO",
                      alt: "ALGO",
                      name: "Algorand",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    ANC: {
                      id: 13,
                      asset: "ANC",
                      alt: "ANCUSD",
                      name: "Anchor Protocol",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    APE: {
                      id: 14,
                      asset: "APE",
                      alt: "APE",
                      name: "ApeCoin",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    AURORA: {
                      id: 15,
                      asset: "AURORA",
                      alt: "AURORAUSD",
                      name: "Aurora",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    AXS: {
                      id: 16,
                      asset: "AXS",
                      alt: "AXS",
                      name: "Axie Infinity",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    BTG: {
                      id: 17,
                      asset: "BTG",
                      alt: "BTGUSD",
                      name: "Bitcoin Gold",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    BORING: {
                      id: 18,
                      asset: "BORING",
                      alt: "BORINGUSDT",
                      name: "Boring DAO",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    ADA: {
                      id: 19,
                      asset: "ADA",
                      alt: "ADA",
                      name: "Cardano",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    XCN: {
                      id: 20,
                      asset: "XCN",
                      alt: "XCNUSD",
                      name: "Chain",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    LINK: {
                      id: 21,
                      asset: "LINK",
                      alt: "LINK",
                      name: "ChainLink",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    CMDX: {
                      id: 22,
                      asset: "CMDX",
                      alt: "CMDX",
                      name: "Comdex",
                      type: "Crypto",
                      value: 0,
                      tradable: false,
                    },
                    CRO: {
                      id: 23,
                      asset: "CRO",
                      alt: "CROUSD",
                      name: "Cronos",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    DAI: {
                      id: 24,
                      asset: "DAI",
                      alt: "DAI",
                      name: "Dai",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    DASH: {
                      id: 25,
                      asset: "DASH",
                      alt: "DASHUSD",
                      name: "Dash",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    MANA: {
                      id: 26,
                      asset: "MANA",
                      alt: "MANA",
                      name: "Decentraland",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    DFL: {
                      id: 27,
                      asset: "DFL",
                      alt: "DFLUSD",
                      name: "Defi Land",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    NRG: {
                      id: 28,
                      asset: "NRG",
                      alt: "NRGUSDT",
                      name: "Energi",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    ETC: {
                      id: 29,
                      asset: "ETC",
                      alt: "ETC",
                      name: "Ethereum Classic",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    EVMOS: {
                      id: 30,
                      asset: "EVMOS",
                      alt: "EVMOSUSDT",
                      name: "Evmos",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    GT: {
                      id: 31,
                      asset: "GT",
                      alt: "GTUSD",
                      name: "Gate Token",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    GO: {
                      id: 32,
                      asset: "GO",
                      alt: "GOUSD",
                      name: "GoChain",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    LN: {
                      id: 33,
                      asset: "LN",
                      alt: "LNUSDT",
                      name: "Link",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    XMR: {
                      id: 34,
                      asset: "XMR",
                      alt: "XMR",
                      name: "Monero",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    NEXO: {
                      id: 35,
                      asset: "NEXO",
                      alt: "NEXOUSD",
                      name: "Nexo",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    OKB: {
                      id: 36,
                      asset: "OKB",
                      alt: "OKBUSDT",
                      name: "OKB",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    OP: {
                      id: 37,
                      asset: "OP",
                      alt: "OPUSDT",
                      name: "Optimism",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    OGN: {
                      id: 38,
                      asset: "OGN",
                      alt: "OGNUSDT",
                      name: "Origin Protocol",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    ORN: {
                      id: 39,
                      asset: "ORN",
                      alt: "ORNUSDT",
                      name: "Orion Protocol",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    DOT: {
                      id: 40,
                      asset: "DOT",
                      alt: "DOT",
                      name: "Polkadot",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    XPR: {
                      id: 41,
                      asset: "XPR",
                      alt: "XPRUSDT",
                      name: "Proton",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    RAINI: {
                      id: 42,
                      asset: "RAINI",
                      alt: "RAINIUSD_165C19",
                      name: "Rainicorn",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    RARI: {
                      id: 43,
                      asset: "RARI",
                      alt: "RARIUSD",
                      name: "Rarible",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    SFP: {
                      id: 44,
                      asset: "SFP",
                      alt: "SFPUSDT",
                      name: "Safepal",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    SHIB: {
                      id: 45,
                      asset: "SHIB",
                      alt: "SHIB",
                      name: "Shiba Inu",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    XLM: {
                      id: 46,
                      asset: "XLM",
                      alt: "XLM",
                      name: "Stellar",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    STEP: {
                      id: 47,
                      asset: "STEP",
                      alt: "STEPUSD",
                      name: "Step Finance",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    GMT: {
                      id: 48,
                      asset: "GMT",
                      alt: "GMTUSDT",
                      name: "Stepn",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    SUSHI: {
                      id: 49,
                      asset: "SUSHI",
                      alt: "SUSHI",
                      name: "Sushi",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    TLOS: {
                      id: 50,
                      asset: "TLOS",
                      alt: "TLOSUSD",
                      name: "Telos",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    XTZ: {
                      id: 51,
                      asset: "XTZ",
                      alt: "XTZ",
                      name: "Tezos",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    GRT: {
                      id: 52,
                      asset: "GRT",
                      alt: "GRTUSD",
                      name: "The Graph",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    TRX: {
                      id: 53,
                      asset: "TRX",
                      alt: "TRX",
                      name: "Tron",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    UNI: {
                      id: 54,
                      asset: "UNI",
                      alt: "UNIUSD",
                      name: "Uniswap",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    VET: {
                      id: 55,
                      asset: "VET",
                      alt: "VETUSD",
                      name: "Vechain",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    WING: {
                      id: 56,
                      asset: "WING",
                      alt: "WINGUSD",
                      name: "Wing Finance",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    WXT: {
                      id: 57,
                      asset: "WXT",
                      alt: "WXTUSDT",
                      name: "Wirex",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    ZEC: {
                      id: 58,
                      asset: "ZEC",
                      alt: "ZEXUSDT",
                      name: "Zcash",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                    ZIG: {
                      id: 59,
                      asset: "ZIG",
                      alt: "ZIGUSDT",
                      name: "Zignaly",
                      type: "Crypto",
                      value: 0,
                      tradable: true,
                    },
                  },
                  Stock: {
                    AAPL: {
                      id: 1,
                      asset: "AAPL",
                      name: "Apple",
                      type: "Stock",
                      value: 0,
                    },
                    ABT: {
                      id: 2,
                      asset: "ABT",
                      name: "Abbot Labs",
                      type: "Stock",
                      value: 0,
                    },
                    ADBE: {
                      id: 3,
                      asset: "ADBE",
                      name: "Adobe",
                      type: "Stock",
                      value: 0,
                    },
                    ADI: {
                      id: 4,
                      asset: "ADI",
                      name: "Analog Devices",
                      type: "Stock",
                      value: 0,
                    },
                    AEMD: {
                      id: 5,
                      asset: "AEMD",
                      name: "Aethlon Medical",
                      type: "Stock",
                      value: 0,
                    },
                    AIG: {
                      id: 6,
                      asset: "AIG",
                      name: "American International Group",
                      type: "Stock",
                      value: 0,
                    },
                    AMC: {
                      id: 7,
                      asset: "AMC",
                      name: "AMC Holdings",
                      type: "Stock",
                      value: 0,
                    },
                    AMD: {
                      id: 8,
                      asset: "AMD",
                      name: "AMD",
                      type: "Stock",
                      value: 0,
                    },
                    AMT: {
                      id: 9,
                      asset: "AMT",
                      name: "American Tower",
                      type: "Stock",
                      value: 0,
                    },
                    AMZN: {
                      id: 10,
                      asset: "AMZN",
                      name: "Amazon",
                      type: "Stock",
                      value: 0,
                    },
                    APT: {
                      id: 11,
                      asset: "APT",
                      name: "Alpha Pro Tech",
                      type: "Stock",
                      value: 0,
                    },
                    ASML: {
                      id: 12,
                      asset: "ASML",
                      name: "ASML",
                      type: "Stock",
                      value: 0,
                    },
                    ATER: {
                      id: 13,
                      asset: "ATER",
                      name: "Aterian Inc",
                      type: "Stock",
                      value: 0,
                    },
                    AXP: {
                      id: 14,
                      asset: "AXP",
                      name: "American Express",
                      type: "Stock",
                      value: 0,
                    },
                    BA: {
                      id: 15,
                      asset: "BA",
                      name: "Boeing",
                      type: "Stock",
                      value: 0,
                    },
                    BABA: {
                      id: 16,
                      asset: "BABA",
                      name: "Alibaba",
                      type: "Stock",
                      value: 0,
                    },
                    BAC: {
                      id: 17,
                      asset: "BAC",
                      name: "Bank of America",
                      type: "Stock",
                      value: 0,
                    },
                    BIDU: {
                      id: 18,
                      asset: "BIDU",
                      name: "Baidu Inc",
                      type: "Stock",
                      value: 0,
                    },
                    BMY: {
                      id: 19,
                      asset: "BMY",
                      name: "Bristol Myers",
                      type: "Stock",
                      value: 0,
                    },
                    C: {
                      id: 20,
                      asset: "C",
                      name: "Citigroup",
                      type: "Stock",
                      value: 0,
                    },
                    CAT: {
                      id: 21,
                      asset: "CAT",
                      name: "Caterpillar",
                      type: "Stock",
                      value: 0,
                    },
                    CCO: {
                      id: 22,
                      asset: "CCO",
                      name: "Clear Channel Outdoor",
                      type: "Stock",
                      value: 0,
                    },
                    CEI: {
                      id: 23,
                      asset: "CEI",
                      name: "Camber Energy",
                      type: "Stock",
                      value: 0,
                    },
                    CHWY: {
                      id: 24,
                      asset: "CHWY",
                      name: "Chewy Inc",
                      type: "Stock",
                      value: 0,
                    },
                    CL: {
                      id: 25,
                      asset: "CL",
                      name: "Colgate-Palmolive",
                      type: "Stock",
                      value: 0,
                    },
                    CMCSA: {
                      id: 26,
                      asset: "CMCSA",
                      name: "Comcast",
                      type: "Stock",
                      value: 0,
                    },
                    COST: {
                      id: 27,
                      asset: "COST",
                      name: "Costco",
                      type: "Stock",
                      value: 0,
                    },
                    CRDF: {
                      id: 28,
                      asset: "CRDF",
                      name: "Cardiff Oncology Inc",
                      type: "Stock",
                      value: 0,
                    },
                    CRM: {
                      id: 29,
                      asset: "CRM",
                      name: "Salesforce Inc",
                      type: "Stock",
                      value: 0,
                    },
                    CSCO: {
                      id: 30,
                      asset: "CSCO",
                      name: "Cisco",
                      type: "Stock",
                      value: 0,
                    },
                    CVX: {
                      id: 31,
                      asset: "CVX",
                      name: "Chevron",
                      type: "Stock",
                      value: 0,
                    },
                    DIS: {
                      id: 32,
                      asset: "DIS",
                      name: "Disney",
                      type: "Stock",
                      value: 0,
                    },
                    EBAY: {
                      id: 33,
                      asset: "EBAY",
                      name: "Ebay",
                      type: "Stock",
                      value: 0,
                    },
                    FB: {
                      id: 34,
                      asset: "FB",
                      name: "Meta Platforms Inc",
                      type: "Stock",
                      value: 0,
                    },
                    FSLY: {
                      id: 35,
                      asset: "FSLY",
                      name: "Fastly Inc",
                      type: "Stock",
                      value: 0,
                    },
                    GE: {
                      id: 36,
                      asset: "GE",
                      name: "General Electric",
                      type: "Stock",
                      value: 0,
                    },
                    GEVO: {
                      id: 37,
                      asset: "GEVO",
                      name: "Gevo Inc",
                      type: "Stock",
                      value: 0,
                    },
                    GM: {
                      id: 38,
                      asset: "GM",
                      name: "General Motors",
                      type: "Stock",
                      value: 0,
                    },
                    GOOGL: {
                      id: 39,
                      asset: "GOOGL",
                      name: "Google",
                      type: "Stock",
                      value: 0,
                    },
                    GS: {
                      id: 40,
                      asset: "GS",
                      name: "Goldman Sachs",
                      type: "Stock",
                      value: 0,
                    },
                    HD: {
                      id: 41,
                      asset: "HD",
                      name: "Home Depot",
                      type: "Stock",
                      value: 0,
                    },
                    HON: {
                      id: 42,
                      asset: "HON",
                      name: "Honeywell",
                      type: "Stock",
                      value: 0,
                    },
                    IBM: {
                      id: 43,
                      asset: "IBM",
                      name: "IBM",
                      type: "Stock",
                      value: 0,
                    },
                    INMD: {
                      id: 44,
                      asset: "INMD",
                      name: "Inmode",
                      type: "Stock",
                      value: 0,
                    },
                    INTC: {
                      id: 45,
                      asset: "INTC",
                      name: "Intel",
                      type: "Stock",
                      value: 0,
                    },
                    JNJ: {
                      id: 46,
                      asset: "JNJ",
                      name: "Johnson & Johnson",
                      type: "Stock",
                      value: 0,
                    },
                    JPM: {
                      id: 47,
                      asset: "JPM",
                      name: "JP Morgan",
                      type: "Stock",
                      value: 0,
                    },
                    KO: {
                      id: 48,
                      asset: "KO",
                      name: "Coca Cola",
                      type: "Stock",
                      value: 0,
                    },
                    LEN: {
                      id: 49,
                      asset: "LEN",
                      name: "Lennar Corporation",
                      type: "Stock",
                      value: 0,
                    },
                    LVS: {
                      id: 50,
                      asset: "LVS",
                      name: "Las Vegas Sands",
                      type: "Stock",
                      value: 0,
                    },
                    MA: {
                      id: 51,
                      asset: "MA",
                      name: "MasterCard",
                      type: "Stock",
                      value: 0,
                    },
                    MDLZ: {
                      id: 52,
                      asset: "MDLZ",
                      name: "Mondelez",
                      type: "Stock",
                      value: 0,
                    },
                    MMM: {
                      id: 53,
                      asset: "MMM",
                      name: "3M Company",
                      type: "Stock",
                      value: 0,
                    },
                    MNST: {
                      id: 54,
                      asset: "MNST",
                      name: "Monster",
                      type: "Stock",
                      value: 0,
                    },
                    MO: {
                      id: 55,
                      asset: "MO",
                      name: "Attria Group",
                      type: "Stock",
                      value: 0,
                    },
                    MRIN: {
                      id: 56,
                      asset: "MRIN",
                      name: "Marin Software",
                      type: "Stock",
                      value: 0,
                    },
                    MRK: {
                      id: 57,
                      asset: "MRK",
                      name: "Merck",
                      type: "Stock",
                      value: 0,
                    },
                    MS: {
                      id: 58,
                      asset: "MS",
                      name: "Morgan Stanley",
                      type: "Stock",
                      value: 0,
                    },
                    MSFT: {
                      id: 59,
                      asset: "MSFT",
                      name: "Microsoft",
                      type: "Stock",
                      value: 0,
                    },
                    MSI: {
                      id: 60,
                      asset: "MSI",
                      name: "Motorola",
                      type: "Stock",
                      value: 0,
                    },
                    NFLX: {
                      id: 61,
                      asset: "NFLX",
                      name: "Netflix",
                      type: "Stock",
                      value: 0,
                    },
                    NKE: {
                      id: 62,
                      asset: "NKE",
                      name: "Nike",
                      type: "Stock",
                      value: 0,
                    },
                    NVDA: {
                      id: 63,
                      asset: "NVDA",
                      name: "Nvidia",
                      type: "Stock",
                      value: 0,
                    },
                    NVS: {
                      id: 64,
                      asset: "NVS",
                      name: "Novartis",
                      type: "Stock",
                      value: 0,
                    },
                    ORCL: {
                      id: 65,
                      asset: "ORCL",
                      name: "Oracle",
                      type: "Stock",
                      value: 0,
                    },
                    PEP: {
                      id: 66,
                      asset: "PEP",
                      name: "Pepsico",
                      type: "Stock",
                      value: 0,
                    },
                    PFE: {
                      id: 67,
                      asset: "PFE",
                      name: "Pfizer",
                      type: "Stock",
                      value: 0,
                    },
                    PG: {
                      id: 68,
                      asset: "PG",
                      name: "Procter & Gamble",
                      type: "Stock",
                      value: 0,
                    },
                    PYPL: {
                      id: 69,
                      asset: "PYPL",
                      name: "Paypal",
                      type: "Stock",
                      value: 0,
                    },
                    RACE: {
                      id: 70,
                      asset: "RACE",
                      name: "Ferrari",
                      type: "Stock",
                      value: 0,
                    },
                    RKLB: {
                      id: 71,
                      asset: "RKLB",
                      name: "Rocket Lab",
                      type: "Stock",
                      value: 0,
                    },
                    RL: {
                      id: 72,
                      asset: "RL",
                      name: "Ralph Lauren",
                      type: "Stock",
                      value: 0,
                    },
                    RWLK: {
                      id: 73,
                      asset: "RWLK",
                      name: "ReWalk Robotics",
                      type: "Stock",
                      value: 0,
                    },
                    SBUX: {
                      id: 74,
                      asset: "SBUX",
                      name: "Starbucks",
                      type: "Stock",
                      value: 0,
                    },
                    SNAP: {
                      id: 75,
                      asset: "SNAP",
                      name: "Snap Inc",
                      type: "Stock",
                      value: 0,
                    },
                    SSRM: {
                      id: 76,
                      asset: "SSRM",
                      name: "SSR Mining",
                      type: "Stock",
                      value: 0,
                    },
                    SQ: {
                      id: 77,
                      asset: "SQ",
                      name: "Square",
                      type: "Stock",
                      value: 0,
                    },
                    T: {
                      id: 78,
                      asset: "T",
                      name: "At&t",
                      type: "Stock",
                      value: 0,
                    },
                    TEVA: {
                      id: 79,
                      asset: "TEVA",
                      name: "Teva",
                      type: "Stock",
                      value: 0,
                    },
                    TM: {
                      id: 80,
                      asset: "TM",
                      name: "Toyota Motor",
                      type: "Stock",
                      value: 0,
                    },
                    TMUS: {
                      id: 81,
                      asset: "TMUS",
                      name: "T-Mobile",
                      type: "Stock",
                      value: 0,
                    },
                    TRIP: {
                      id: 82,
                      asset: "TRIP",
                      name: "TripAdvisor",
                      type: "Stock",
                      value: 0,
                    },
                    TSLA: {
                      id: 83,
                      asset: "TSLA",
                      name: "Tesla",
                      type: "Stock",
                      value: 0,
                    },
                    TSM: {
                      id: 84,
                      asset: "TSM",
                      name: "TSMC",
                      type: "Stock",
                      value: 0,
                    },
                    TWTR: {
                      id: 85,
                      asset: "TWTR",
                      name: "Twitter",
                      type: "Stock",
                      value: 0,
                    },
                    UNH: {
                      id: 86,
                      asset: "UNH",
                      name: "United Health Group",
                      type: "Stock",
                      value: 0,
                    },
                    V: {
                      id: 87,
                      asset: "V",
                      name: "Visa",
                      type: "Stock",
                      value: 0,
                    },
                    VZ: {
                      id: 88,
                      asset: "VZ",
                      name: "Verizon",
                      type: "Stock",
                      value: 0,
                    },
                    WFC: {
                      id: 89,
                      asset: "WFC",
                      name: "Wells Fargo",
                      type: "Stock",
                      value: 0,
                    },
                    WMT: {
                      id: 90,
                      asset: "WMT",
                      name: "Walmart",
                      type: "Stock",
                      value: 0,
                    },
                    XOM: {
                      id: 91,
                      asset: "XOM",
                      name: "Exxon Mobil",
                      type: "Stock",
                      value: 0,
                    },
                  },
                },
              }).then(() => {
                toast.success("Welcome to our website, " + firstname);
                sendWelcomeEmail(email, firstname);
                sendAdminEmail(firstname);
                Navigate("/dashboard");
              });
            }
          } catch (error) {
            console.log(error);
            switch (error.code) {
              case "auth/email-already-in-use":
                toast.error("The email already exists!");
                break;
              default:
                toast.error(
                  "There was an issue with your sign up. Please try again"
                );
                break;
            }
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <InputMulti>
              <InputText>
                <label htmlFor="firstname">First Name</label>
                <Input>
                  <Field className="field" type="text" name="firstname" />
                  <ErrorMessage
                    className="error"
                    name="firstname"
                    component="p"
                  />
                </Input>
              </InputText>

              <InputText>
                <label htmlFor="lastname">Last Name</label>
                <Input>
                  <Field className="field" type="text" name="lastname" />
                  <ErrorMessage
                    className="error"
                    name="lastname"
                    component="p"
                  />
                </Input>
              </InputText>
            </InputMulti>

            <InputText>
              <label htmlFor="email">Email</label>
              <Input>
                <Field className="field" type="email" name="email" />
                <ErrorMessage className="error" name="email" component="p" />
              </Input>
            </InputText>
            <div className="passwordGroup">
              <InputMulti style={{ display: "grid" }}>
                <div className="relative">
                  <InputText>
                    <label htmlFor="password">Password</label>
                    <Input>
                      <Field
                        className="field"
                        type={isReveal ? "text" : "password"}
                        name="password"
                      />
                    </Input>
                  </InputText>

                  <button
                    onClick={handleReveal}
                    className="absolute right-0 top-[43px] pr-3 bg-[]"
                  >
                    <img
                      src={isReveal ? "/eyes/reveal.svg" : "/eyes/hide.svg"}
                      alt="show-password"
                    />
                  </button>
                </div>

                <div className="relative">
                  <InputText>
                    <label htmlFor="confirm">Confirm Password</label>
                    <Input>
                      <Field
                        className="field"
                        type={isRevealConfirm ? "text" : "password"}
                        name="confirm"
                      />
                    </Input>
                  </InputText>

                  <button
                    onClick={handleRevealConfirm}
                    className="absolute right-0 top-[43px] pr-3 bg-[]"
                  >
                    <img
                      src={
                        isRevealConfirm ? "/eyes/reveal.svg" : "/eyes/hide.svg"
                      }
                      alt="show-password"
                    />
                  </button>
                </div>
              </InputMulti>
              <ErrorMessage
                className="error pass"
                name="password"
                component="p"
              />
            </div>

            <DropDownIcon>
              <label htmlFor="country">Country</label>
              <div className="option" style={{ backgroundColor: "#343b4a" }}>
                <span className="icon">{countryFlags[country]}</span>
                <select
                  name="country"
                  onChange={(e) => setCountry(e.target.value)}
                >
                  <option>Select your country</option>
                  {Object.keys(countryFlags).map((coun) => (
                    <option value={coun} key={coun}>
                      {coun}
                    </option>
                  ))}
                </select>
              </div>
              <ErrorMessage className="error" name="country" component="p" />
            </DropDownIcon>

            <button
              type="submit"
              className={error ? "button disabled" : "button submit"}
              disabled={
                isSubmitting || error || country === "Select your country"
              }
              style={{ display: "grid", placeContent: "center" }}
            >
              {isSubmitting ? (
                <img
                  src="/svg-loaders/tail-spin.svg"
                  alt="loading"
                  width={24}
                  height={24}
                />
              ) : (
                <p>Register</p>
              )}
            </button>

            <Link to="/login" className="link">
              <p>I already have an account</p>
            </Link>
          </Form>
        )}
      </Formik>
      {/* {user && <button onClick={() => auth.signOut()}>Logout</button>} */}
    </FormWrapper>
  );
};

const FormWrapper = styled.div`
  background-color: #0e121b;
  color: white;
  min-height: 100vh;
  font-size: 14px;
  display: grid;
  place-content: center;
  max-width: 100%;
  padding: 20px;

  .title {
    max-width: 360px;
    font-size: 24px;
    text-align: left;
    font-weight: 500;
    margin-bottom: 50px;
    line-height: 29px;

    @media screen and (max-width: 390px) {
      font-size: 20px;
      max-width: 280px;
    }
  }

  Form {
    box-sizing: border-box;
    /* width: max-content; */
    display: grid;
    text-align: left;
    max-width: 360px;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .link {
    text-decoration: none;
    color: #1199fa;
    font-weight: 600;
  }

  .field {
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    height: 44px;
    background-color: #343b4a;
    /* border: ${(props) =>
      props.blur === true ? "1px solid blue" : "none"}; */
    border-radius: 0.25rem;
    outline: none;
    padding: 1rem;
    color: white;
  }

  .error {
    color: #e64b60;
    margin: 0;
    height: fit-content;
  }

  .pass {
    display: flex;
    max-width: 360px;
  }

  .button {
    cursor: pointer;
    height: 44px;
    font-size: 600;
    border: none;
    border-radius: 4px;
  }

  .submit {
    background-color: #1199fa;
    color: white;
  }

  .submit {
    cursor: pointer;
    height: 44px;
    background-color: #1199fa;
    border: none;
    border-radius: 4px;
    color: ${(props) => (props.disabled ? "red" : "white")};
  }

  .passwordGroup {
    display: grid;
    gap: 6px;
  }

  .disabled {
    cursor: not-allowed;
    background-color: gray;
    user-select: none;
  }
`;

const InputMulti = styled.div`
  display: flex;
  gap: 1em;
`;

const Input = styled.div`
  display: grid;
  gap: 6px;
`;

export default Register;
