import { Menu, SubText } from "../styles";
import { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  collection,
  query,
  getDocs,
  orderBy,
  updateDoc,
  doc,
  serverTimestamp,
  setDoc,
  increment,
} from "firebase/firestore";
import { db } from "../firebase/firebase";
import CircularLoader from "../pages/CircularLoader";
import { Grow, ClickAwayListener } from "@mui/material";
import CloseTradeModal from "../pages/ClosetradeModal";
import WithdrawalDetailsModal from "../pages/WithdrawalDetailsModal";
import { userContext } from "../context/userContext";
import toast from "react-hot-toast";
import emailjs from "@emailjs/browser";

const WithdrawalsTable = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [withdrawals, setWithdrawals] = useState([]);
  const [users, setUsers] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [details, setDetails] = useState([]);
  const [withdrawType, setWithdrawType] = useState("");
  const [action, setAction] = useState("");
  const [id, setID] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const { currentPrices } = useContext(userContext);

  useEffect(() => {
    const withdrawalsList = [];
    async function getWithdrawals() {
      const q = query(collection(db, "withdrawals"), orderBy("date", "desc"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        withdrawalsList.push(doc.data());
        setWithdrawals(withdrawalsList);
      });
    }

    getWithdrawals();
  }, []);

  useEffect(() => {
    if (!withdrawals) {
      setIsLoading(true);
    }

    if (withdrawals) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [withdrawals]);

  useEffect(() => {
    const userList = [];
    async function getUsers() {
      const q = query(collection(db, "users"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        userList.push(doc.data());
        setUsers(userList);
      });
    }

    getUsers();
  }, []);

  useEffect(() => {
    if (!users) {
      setIsLoading(true);
    }

    if (users) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [users]);

  function getTime(milliSeconds) {
    const seconds = milliSeconds / 1000;
    const minutes = seconds / 60;
    const hours = minutes / 60;
    const days = hours / 24;
    const weeks = days / 7;

    if (seconds > 60 && minutes < 60) {
      return (
        Math.floor(minutes) +
        (Math.floor(minutes) > 1 ? " minutes ago" : " minute ago")
      );
    }

    if (seconds > 60 && minutes > 60 && hours < 24) {
      return (
        Math.floor(hours) + (Math.floor(hours) > 1 ? " hours ago" : " hour ago")
      );
    }

    if (seconds > 60 && minutes > 60 && hours > 24 && days < 7) {
      return (
        Math.floor(days) + (Math.floor(days) > 1 ? " days ago" : " day ago")
      );
    }

    if (seconds > 60 && minutes > 60 && hours > 24 && days > 7) {
      return (
        Math.floor(weeks) + (Math.floor(weeks) > 1 ? " weeks ago" : " week ago")
      );
    }

    return (
      Math.floor(seconds) +
      (Math.floor(seconds) > 1 ? " seconds ago" : " seconds ago")
    );
  }

  const cardRef = useRef();
  const [activeRef, setActiveRef] = useState("");

  const [approvedWithdrawals, setApprovedWithdrawals] = useState([]);
  const [declinedWithdrawals, setDeclinedWithdrawals] = useState([]);
  const [pendingWithdrawals, setPendingWithdrawals] = useState([]);

  useEffect(() => {
    const approvedWithdrawalsList = [];
    const pendingWithdrawalsList = [];
    const declinedWithdrawalsList = [];

    withdrawals.forEach((withdrawal) => {
      switch (withdrawal.status) {
        case "pending":
          pendingWithdrawalsList.push(withdrawal);
          break;
        case "approved":
          approvedWithdrawalsList.push(withdrawal);
          break;
        case "declined":
          declinedWithdrawalsList.push(withdrawal);
          break;
        default:
          break;
      }
    });

    setApprovedWithdrawals(approvedWithdrawalsList);
    setPendingWithdrawals(pendingWithdrawalsList);
    setDeclinedWithdrawals(declinedWithdrawalsList);
  }, [
    withdrawals,
    approvedWithdrawals,
    declinedWithdrawals,
    pendingWithdrawals,
  ]);

  function handleShowDetails(details, type) {
    setDetails(details);
    setShowDetails(true);
    setWithdrawType(type);
  }

  function handleWithdrawal(user, type, amount, ref, details) {
    setActiveRef(ref);
    setID(user);
  }

  useEffect(() => {
    if (id) {
      users.forEach((us) => {
        if (us.id === id) {
          setUserEmail(us.email);
          setUserName(us.firstname);
        }
      });
    }
  }, [users, id]);

  // Determines if approve, withdraw or request and calls the functions
  function handleAction(act, ref, user, type, amount, details, asset) {
    setActiveRef("");
    setAction(ref);
    if (act === "approve") {
      // sendApproveEmail(
      //   ref,
      //   user,
      //   userEmail,
      //   userName,
      //   type,
      //   amount,
      //   details,
      //   asset
      // );
      handleApprove(ref, user, asset, amount);
    }

    if (act === "decline") {
      // sendDeclineEmail(
      //   ref,
      //   user,
      //   userEmail,
      //   userName,
      //   type,
      //   amount,
      //   details,
      //   asset
      // );
      handleDecline(ref, user, asset, amount);
    }

    // if (act === "request") {
    //   sendRequestEmail(
    //     ref,
    //     user,
    //     userEmail,
    //     userName,
    //     type,
    //     Number(currentPrices[type] / amount).toFixed(3)
    //   );
    // }
  }

  // Approval Email and calls to change doc status
  async function sendApproveEmail(
    ref,
    user,
    email,
    name,
    type,
    amount,
    details,
    asset
  ) {
    let acc;

    if (type === "Crypto") {
      acc = `${amount} should be in your ${asset} wallet with address ${details[1].value} soon`;
    }

    if (type === "Bank Transfer") {
      acc = `${amount} ${asset} should be in your Bank account soon.`;
    }

    if (type === "Paypal") {
      acc = `${amount} ${asset} should be in your Paypal account soon.`;
    }

    const params = {
      action: "Withdrawal",
      final: "Completed",
      user_name: name,
      amount: amount,
      asset,
      to_or_from: "from",
      from_name: "Prime Venture Partners",
      broker_web_to: "primeventurepartners.net",
      logo_link: "https://primeventurepartners.net/logo.png",
      accountType: type === "Crypto" ? "crypto" : "fiat",
      message: "has been completed",
      detail: acc,
      broker_web: "https://primeventurepartners.net",
      broker_email_to: "mailto:support@primeventurepartners.net",
      to_email: email,
      from_email: "helpdesk@primeventurepartners.net",
      reply_to: "support@primeventurepartners.net",
    };

    emailjs
      .send("service_5jaijh8", "template_vck7wtq", params, "9IOr2_lHheTH7RW1k")
      .then(() => {
        handleApprove(ref, user, asset, amount);
      })
      .catch((error) => {
        toast.error("There was an error with this approval");
        console.log(error);
        setAction("");
      });
  }

  // changes the status of the withdrawal doc and calls to set notification
  async function handleApprove(ref, user, asset, amount) {
    const selected = doc(db, "withdrawals", ref);
    await updateDoc(selected, {
      status: "approved",
    })
      .then(() => {
        setApproveNotification(user, asset, amount);
      })
      .catch((error) => {
        toast.error(
          "There was a problem with your request.\n \n Please try again later."
        );
        setAction("");
      });
  }

  // Decline Email and calls to change doc status
  async function sendDeclineEmail(
    ref,
    user,
    email,
    name,
    type,
    amount,
    details,
    asset
  ) {
    const params = {
      action: "Withdrawal",
      final: "Declined",
      user_name: name,
      amount: amount,
      action_name: "withdraw",
      asset: type,
      to_or_from: "from",
      from_name: "Prime Venture Partners",
      accountType: type === "Crypto" ? "crypto" : "fiat",
      message: "has been declined",
      logo_link: "https://primeventurepartners.net/logo.png",
      detail:
        "Kindly contact your account manager or chat support for further information.",
      broker_web_to: "primeventurepartners.net",
      broker_web: "https://primeventurepartners.net",
      broker_email_to: "mailto:support@primeventurepartners.net",
      to_email: email,
      from_email: "helpdesk@primeventurepartners.net",
      reply_to: "support@primeventurepartners.net",
      action_name: "withdraw",
    };

    emailjs
      .send("service_5jaijh8", "template_vck7wtq", params, "9IOr2_lHheTH7RW1k")
      .then(() => {
        handleDecline(ref, user, asset, amount);
      })
      .catch((error) => {
        toast.error("An error occured. Please try again later");
        console.log(error);
        setAction("");
      });
  }

  // changes the status of the withdrawa; doc and calls increment
  async function handleDecline(ref, user, asset, amount) {
    const selected = doc(db, "withdrawals", ref);
    await updateDoc(selected, {
      status: "declined",
    })
      .then(() => {
        if (asset === "USD") {
          incrementAmount(ref, user, asset, amount);
        } else {
          incrementCrypto(ref, user, asset, amount);
        }
      })
      .catch((error) => {
        toast.error(
          "There was a problem with your request.\n \n Please try again later."
        );
        setAction("");
      });
  }

  // Decrement crypto
  const incrementCrypto = async (ref, user, asset, amount) => {
    const q = doc(db, "accounts", user);
    const key = `live.Crypto.${asset}.value`;
    try {
      await updateDoc(q, {
        [key]: increment(amount),
      }).then(() => {
        setDeclineNotification(user, asset, amount);
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Increment fiat
  const incrementAmount = async (ref, user, asset, amount) => {
    const q = doc(db, "accounts", user);
    try {
      await updateDoc(q, {
        "live.Fiat.value": increment(amount),
      }).then(() => {
        setDeclineNotification(user, asset, amount);
      });
    } catch (error) {
      console.log(error);
    }
  };

  //send approve notification
  async function setApproveNotification(user, asset, amount) {
    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      user.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    const amountUSD = Number(currentPrices[asset] * amount).toFixed(2);
    await setDoc(doc(db, "notifications", str), {
      date: serverTimestamp(),
      ref: str,
      user,
      title: "Withdrawal",
      text: "Your withdrawal of $" + amountUSD + " was successful.",
    })
      .then(() => {
        toast.success("Approved successfully");
        setAction("");
      })
      .catch((error) => {
        toast.error("Approval could not complete.");
      });
  }

  //send decline notification
  async function setDeclineNotification(user, asset, amount) {
    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      user.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    const amountUSD = Number(currentPrices[asset] * amount).toFixed(2);
    await setDoc(doc(db, "notifications", str), {
      date: serverTimestamp(),
      ref: str,
      user,
      title: "Withdrawal",
      text: "Your withdrawal of $" + amountUSD + " failed.",
    })
      .then(() => {
        toast.success("Declined successfully");
        setAction("");
      })
      .catch((error) => {
        toast.error("Decline request could not complete.");
      });
  }

  return (
    <Wrapper>
      <SubText className="top">Withdrawals</SubText>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <>
          <WithdrawalDetailsModal
            open={{ showDetails, setShowDetails }}
            details={details}
            type={withdrawType}
          />
          <table className="scrollbar-hidden">
            <thead>
              <tr>
                <th>User</th>
                <th>Ref</th>
                <th>Type</th>
                <th>Amount</th>
                <th>Date</th>
                <th>Details</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {pendingWithdrawals.map(
                (withdrawal) =>
                  withdrawal.status === "pending" && (
                    <tr key={withdrawal.ref} className="row">
                      <td>
                        {users.map(
                          (user) =>
                            user.id === withdrawal.user && (
                              <div className="name user" key={withdrawal.ref}>
                                {user.photoURL && (
                                  <div style={{ position: "relative" }}>
                                    <img
                                      src={user.photoURL}
                                      alt="user"
                                      className="profile"
                                    />
                                    {user.admin && (
                                      <span
                                        style={{
                                          position: "absolute",
                                          bottom: "0",
                                          right: "0",
                                        }}
                                      >
                                        <img
                                          src="/extraicons/checkmark.svg"
                                          alt="admin"
                                        />
                                      </span>
                                    )}
                                  </div>
                                )}

                                {!user.photoURL && (
                                  <div style={{ position: "relative" }}>
                                    <span className="avatar">
                                      {user.firstname.slice(0, 1)}
                                    </span>

                                    {user.admin && (
                                      <span
                                        style={{
                                          position: "absolute",
                                          bottom: "-4px",
                                          right: "-4px",
                                        }}
                                      >
                                        <img
                                          src="/extraicons/checkmark.svg"
                                          alt="admin"
                                        />
                                      </span>
                                    )}
                                  </div>
                                )}

                                <p>{user.firstname}</p>
                              </div>
                            )
                        )}
                      </td>
                      <td className="ref">
                        <p>{withdrawal.ref}</p>
                      </td>
                      <td>
                        <p>{withdrawal.type}</p>
                      </td>
                      <td>
                        <p>
                          {withdrawal.amount} {withdrawal.asset}
                        </p>
                      </td>
                      <td>
                        <p>{getTime(new Date() - withdrawal.date.toDate())}</p>
                      </td>
                      <td>
                        <button
                          className="actionButton"
                          onClick={() =>
                            handleShowDetails(
                              withdrawal.details,
                              withdrawal.type
                            )
                          }
                        >
                          <p className="actionText">Show</p>
                        </button>
                      </td>
                      <td>
                        {withdrawal.status === "pending" && (
                          <div style={{ position: "relative" }}>
                            <button
                              ref={cardRef}
                              style={{
                                width: "100%",
                                boxSizing: "border-box",
                                padding: "0.21rem",
                                display: "grid",
                                placeContent: "center",
                                height: "42px",
                              }}
                              className="pending"
                              onClick={() =>
                                handleWithdrawal(
                                  withdrawal.user,
                                  withdrawal.type,
                                  withdrawal.amount,
                                  withdrawal.ref,
                                  withdrawal.details
                                )
                              }
                            >
                              {action === withdrawal.ref ? (
                                <img
                                  src="/svg-loaders/tail-spin.svg"
                                  alt="loading"
                                  width={24}
                                  height={24}
                                />
                              ) : (
                                <p>Pending</p>
                              )}
                            </button>
                            {activeRef === withdrawal.ref && (
                              <>
                                <ClickAwayListener
                                  onClickAway={() => setActiveRef("")}
                                >
                                  <Grow
                                    in={activeRef === withdrawal.ref}
                                    style={{ transformOrigin: "0 0 0 0" }}
                                    {...(activeRef === withdrawal.ref
                                      ? { timeout: 300 }
                                      : {})}
                                  >
                                    <div className="actionMenu">
                                      <p
                                        onClick={() =>
                                          handleAction(
                                            "approve",
                                            withdrawal.ref,
                                            withdrawal.user,
                                            withdrawal.type,
                                            withdrawal.amount,
                                            withdrawal.details,
                                            withdrawal.asset
                                          )
                                        }
                                      >
                                        Approve
                                      </p>
                                      <p
                                        onClick={() =>
                                          handleAction(
                                            "decline",
                                            withdrawal.ref,
                                            withdrawal.user,
                                            withdrawal.type,
                                            withdrawal.amount,
                                            withdrawal.details,
                                            withdrawal.asset
                                          )
                                        }
                                      >
                                        Decline
                                      </p>
                                    </div>
                                  </Grow>
                                </ClickAwayListener>
                              </>
                            )}
                          </div>
                        )}
                      </td>
                    </tr>
                  )
              )}
              {approvedWithdrawals.map(
                (withdrawal) =>
                  withdrawal.status === "approved" && (
                    <tr key={withdrawal.ref} className="row">
                      <td>
                        {users.map(
                          (user) =>
                            user.id === withdrawal.user && (
                              <div className="name user" key={withdrawal.ref}>
                                {user.photoURL && (
                                  <div style={{ position: "relative" }}>
                                    <img
                                      src={user.photoURL}
                                      alt="user"
                                      className="profile"
                                    />
                                    {user.admin && (
                                      <span
                                        style={{
                                          position: "absolute",
                                          bottom: "0",
                                          right: "0",
                                        }}
                                      >
                                        <img
                                          src="/extraicons/checkmark.svg"
                                          alt="admin"
                                        />
                                      </span>
                                    )}
                                  </div>
                                )}

                                {!user.photoURL && (
                                  <div style={{ position: "relative" }}>
                                    <span className="avatar">
                                      {user.firstname.slice(0, 1)}
                                    </span>

                                    {user.admin && (
                                      <span
                                        style={{
                                          position: "absolute",
                                          bottom: "-4px",
                                          right: "-4px",
                                        }}
                                      >
                                        <img
                                          src="/extraicons/checkmark.svg"
                                          alt="admin"
                                        />
                                      </span>
                                    )}
                                  </div>
                                )}

                                <p>{user.firstname}</p>
                              </div>
                            )
                        )}
                      </td>
                      <td className="ref">
                        <p>{withdrawal.ref}</p>
                      </td>
                      <td>
                        <p>{withdrawal.type}</p>
                      </td>
                      <td>
                        <p>
                          {withdrawal.amount} {withdrawal.asset}
                        </p>
                      </td>
                      <td>
                        <p>{getTime(new Date() - withdrawal.date.toDate())}</p>
                        {/* {user.isVerified && <button>active</button>} */}
                        {/* {!user.isVerified && <button>passive</button>} */}
                      </td>
                      <td>
                        <button
                          className="actionButton"
                          onClick={() =>
                            handleShowDetails(
                              withdrawal.details,
                              withdrawal.type
                            )
                          }
                        >
                          <p className="actionText">Show</p>
                        </button>
                      </td>
                      <td>
                        {withdrawal.status === "approved" && (
                          <button
                            disabled
                            className="approved"
                            style={{
                              width: "100%",
                              boxSizing: "border-box",
                              padding: "0.21rem",
                            }}
                          >
                            <p>Approved</p>
                          </button>
                        )}
                      </td>
                    </tr>
                  )
              )}
              {declinedWithdrawals.map(
                (withdrawal) =>
                  withdrawal.status === "declined" && (
                    <tr key={withdrawal.ref} className="row">
                      <td>
                        {users.map(
                          (user) =>
                            user.id === withdrawal.user && (
                              <div className="name user" key={withdrawal.ref}>
                                {user.photoURL && (
                                  <div style={{ position: "relative" }}>
                                    <img
                                      src={user.photoURL}
                                      alt="user"
                                      className="profile"
                                    />
                                    {user.admin && (
                                      <span
                                        style={{
                                          position: "absolute",
                                          bottom: "0",
                                          right: "0",
                                        }}
                                      >
                                        <img
                                          src="/extraicons/checkmark.svg"
                                          alt="admin"
                                        />
                                      </span>
                                    )}
                                  </div>
                                )}

                                {!user.photoURL && (
                                  <div style={{ position: "relative" }}>
                                    <span className="avatar">
                                      {user.firstname.slice(0, 1)}
                                    </span>

                                    {user.admin && (
                                      <span
                                        style={{
                                          position: "absolute",
                                          bottom: "-4px",
                                          right: "-4px",
                                        }}
                                      >
                                        <img
                                          src="/extraicons/checkmark.svg"
                                          alt="admin"
                                        />
                                      </span>
                                    )}
                                  </div>
                                )}

                                <p>{user.firstname}</p>
                              </div>
                            )
                        )}
                      </td>
                      <td className="ref">
                        <p>{withdrawal.ref}</p>
                      </td>
                      <td>
                        <p>{withdrawal.type}</p>
                      </td>
                      <td>
                        <p>
                          {withdrawal.amount} {withdrawal.asset}
                        </p>
                      </td>
                      <td>
                        <p>{getTime(new Date() - withdrawal.date.toDate())}</p>
                        {/* {user.isVerified && <button>active</button>} */}
                        {/* {!user.isVerified && <button>passive</button>} */}
                      </td>
                      <td>
                        <button
                          className="actionButton"
                          onClick={() =>
                            handleShowDetails(
                              withdrawal.details,
                              withdrawal.type
                            )
                          }
                        >
                          <p className="actionText">Show</p>
                        </button>
                      </td>
                      <td>
                        {withdrawal.status === "declined" && (
                          <button
                            disabled
                            style={{
                              width: "100%",
                              boxSizing: "border-box",
                              padding: "0.21rem",
                            }}
                            className="declined"
                          >
                            <p>Declined</p>
                          </button>
                        )}
                      </td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  margin-top: 30px;
  background: #131824;
  border-radius: 10px;
  max-width: 100%;

  .avatar {
    width: 32px;
    place-content: center;
    height: 32px;
    align-items: center;
    /* padding: 0.5rem; */
    background-color: #c4c4c4;
    display: flex;
    color: #131824;
    border-radius: 50%;
  }

  .profile {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
  }

  .declined {
    /* border: 0.5px solid #1199fa; */
    border: none;
    border-radius: 6px;
    outline: none;
    background: rgba(230, 75, 96, 0.13);

    p {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      padding: 0.5rem;
      color: #e64b60;
    }
  }

  .approved {
    /* border: 0.5px solid #1199fa; */
    border: none;
    border-radius: 6px;
    outline: none;
    background: rgba(32, 188, 164, 0.13);

    p {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      padding: 0.5rem;
      color: #20bca4;
    }
  }

  .pending {
    background: rgba(35, 163, 251, 0.13);
    border-radius: 6px;
    border: none;
    outline: none;

    p {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      padding: 0.5rem 1rem;
      color: #1199fa;
    }
  }

  .actionMenu {
    appearance: none;
    position: absolute;
    left: -100px;
    z-index: 1000;
    width: 120px;
    top: -100px;
    /* bottom: -40px; */
    border: 0.5px solid #1f273a;
    background: #161c2a;
    border-radius: 8px;
    padding: 0.4rem;
    display: grid;
    gap: 0.5rem;

    p {
      padding: 0.5rem;
      cursor: pointer;
      transition: background-color 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    p:hover {
      background-color: #1f273a;
      border-radius: 4px;
    }
  }

  .action {
    align-self: flex-end;
  }

  .asset {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
  .top {
    top: 0;
    width: 100%;
    background-color: #19202f;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    box-sizing: border-box;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  table {
    display: grid;
    gap: 1rem;
    padding: 1rem;
    overflow-x: auto;
    max-width: 100%;
    overflow-x: auto;

    .name {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      text-transform: capitalize;

      img {
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .ref,
    .email {
      box-sizing: border-box;

      p {
        text-overflow: ellipsis;
        max-width: 85px;
        overflow: hidden;
      }
    }
  }

  thead {
    border: 1px solid #1f273a;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 700;
    color: #828ea1;
    font-size: 14px;
    line-height: 18px;
  }

  tbody {
    font-weight: 510;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    display: grid;
    gap: 0.7rem;

    .row {
      border-radius: 4px;
      cursor: pointer;
    }

    .row:hover {
      background-color: #1f273a;
    }
  }

  th,
  td {
    width: 144px;
    text-align: left;
  }

  tr {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
    box-sizing: border-box;
  }
`;

export default WithdrawalsTable;
